import richTextBoxCompsToPackages from './legacy/components/RichTextBox/compsToPackages';
import sliderCompsToPackages from './components/Slider/compsToPackages';
import timePickerCompsToPackages from './components/TimePicker/compsToPackages';
import addressInputCompsToPackages from './components/AddressInput/compsToPackages';
import stylableLineCompsToPackages from './components/StylableLine/compsToPackages';
import ratingsInputCompsToPackages from './components/RatingsInput/compsToPackages';
import ratingsDisplayCompsToPackages from './components/RatingsDisplay/compsToPackages';
import videoPlayerCompsToPackages from './components/VideoPlayer/compsToPackages';

export default Object.assign({},
  richTextBoxCompsToPackages,
  sliderCompsToPackages,
  timePickerCompsToPackages,
  addressInputCompsToPackages,
  stylableLineCompsToPackages,
  ratingsInputCompsToPackages,
  ratingsDisplayCompsToPackages,
  videoPlayerCompsToPackages
);
